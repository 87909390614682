import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-leftimage-sm',
  templateUrl: './leftimage-sm.component.html',
  styleUrl: '../../style.css'
})
export class LeftImageSMComponent {
  @Input() leftImageData: any;

  constructor(private router: Router) {

  }

  redirect() {
    if (this.leftImageData.btnLink.includes('http') || this.leftImageData.btnLink.includes('www')) {
      window.open(this.leftImageData.btnLink, '_blank');
      return;
    }
    this.router.navigate([this.leftImageData.btnLink])
  }
}
