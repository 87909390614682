import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeftImageSMComponent } from './leftimage-sm.component';



@NgModule({
  declarations: [
    LeftImageSMComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    LeftImageSMComponent
  ]
})
export class LeftImgSMModule { }
