<section class="container-fluid" [ngClass]="leftImageData.class">
  <div class="row d-flex align-items-center justify-content-center">

    <div class="col-lg-4 col-md-12 order-lg-3 order-3">
      <div class="text-content">
        <h1 [innerHTML]="leftImageData.heading"></h1>
        <p [innerHTML]="leftImageData.description"></p>
        <button class="btn btn-primary" *ngIf="leftImageData.btnTxt !==''" (click)="redirect()" >{{leftImageData.btnTxt}}</button>
      </div>
    </div>


  


    <div class="col-lg-1 col-md-2 order-lg-2 order-2"></div>

    <div class="col-xl-5  col-lg-6  col-xxl-4 col-md-12 order-lg-1 order-1"> 
      <figure class="image-container text-lg-end text-center">
        <!-- If the file is an image, render the img tag -->
        <img *ngIf="leftImageData.img.endsWith('.jpg') || leftImageData.img.endsWith('.png') || leftImageData.img.endsWith('.jpeg') || leftImageData.img.endsWith('.svg') || leftImageData.img.endsWith('.gif')"
             [src]="leftImageData.img" loading="eager" alt="Logistics solutions" class="img-fluid" />
      
        <!-- If the file is a video, render the video tag -->
        <video *ngIf="leftImageData.img.endsWith('.mp4')" [src]="leftImageData.img" class="" 
        autoplay 
        loop 
        playsinline 
        muted>
          Your browser does not support the video tag.
        </video>
      </figure>
    </div>

  </div>
</section>